import React from "react"
import Button from '../components/Button'
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import AniLink from "gatsby-plugin-transition-link/AniLink";

const scrollTo = (value) => {
    console.log("Hola scroll", value)
}

// const goToForm = () => {
// 	console.log("hola mundo")
// }

let formRef = "asdfajksdhfaksd"

// let introImage = ""

let customers = [{
    logo: '',
    label: ''
},{
    logo: '',
    label: ''
},{
    logo: '',
    label: ''
},{
    logo: '',
    label: ''
},{
    logo: '',
    label: ''
},{
    logo: '',
    label: ''
},{
    logo: '',
    label: ''
},]


export default function about(props) {
    return (
        <>
            <div className="intro-container bg-ornament bg-right bg-auto bg-no-repeat mb-12">
                <div className="container mx-auto grid lg:grid-cols-3 lg:px-0 grid-cols-1 px-12 gap-12 md:gap-0 lg:mb-12 justify-center h-full">
                    <div className="col-span-1 lg:col-span-2 flex flex-col justify-center lg:pl-12 md:px-12">
                        <h1 className="text-5xl md:text-6xl font-bold text-gray mb-8 lg:pr-24">
                            Aumenta la productividad en tu organización
                        </h1>
                        <span className="lg:text-gray-400 text-gray text-lg mb-12 lg:pr-24">
                            Colabora rápidamente, responde a los cambios comerciales, y brinda una experiencia única a tus clientes y empleados con nuestras herramientas.
                        </span>
                        <div className="flex flex-row lg:justify-start justify-center">
                            <Button classes="px-8 py-4 bg-green-900 text-white rounded-lg mr-8" onClick={() => scrollTo(formRef)}>
                                Iniciar ahora
                            </Button>
                        </div>
                    </div>
                    <div className="hidden lg:col-span-1 lg:pr-12 lg:flex flex-row justify-start items-center transition ease-in-out duration-300">
                        <img src="https://cdn01.prd.pentcloud.com/site/Soluciones/4.jpg"  alt="Pentcloud" className="rounded-3xl shadow-xl transition ease-in-out duration-300" />
                        {/* <div className="h-full w-full rounded-xl bg-gray-400">

                        </div> */}

                    </div>
                </div>
            </div>
            <div className="container mx-auto grid lg:grid-cols-3 grid-cols-1 px-12 py-24">
                <div className="col-span-1 flex flex-col justify-center">
                    <h2 className="text-2xl font-bold text-gray mb-4">
                        Con la confianza de más de 1000 empresas que utilizan nuestras soluciones
                    </h2>
                    <span className="lg:text-gray-400 text-gray text-base mb-12 lg:pr-24">
                        Cambiamos la forma en la que las personas realizan su trabajo diario, acercándo herramientas de clase mundial a todo tipo de empresas.
                    </span>
                </div>
                <div className="col-span-2 flex flex-row flex-wrap items-center justify-evenly">
                    {customers.map((customer, i) =>
                        <div className="pr-12 w-1/2 lg:w-1/3 mb-12 last:mb-0" key={i}>
                            <div className="bg-gray-200 shadow-lg rounded-base h-20">
                                
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <div className="px-12 py-12 lg:py-40 bg-gray-100 rounded-tr-4xl">
                <div className="container mx-auto grid grid-cols-1 lg:grid-cols-3 gap-12 lg:gap-24">
                    <div className="col-span-1">
                        <div className="h-full w-full rounded-xl bg-gray-400 overflow-hidden flex place-content-center">
                            <StaticImage src="https://cdn01.prd.pentcloud.com/site/Soluciones/1.jpg" className="object-contain w-full" quality="100" alt="Learning"/>
                        </div>
                    </div>
                    <div className="col-span-1 lg:col-span-2 flex flex-col">
                        <div className="flex lg:flex-row flex-col">
                            <div className="flex flex-col mr-0 lg:mr-12">
                                <h2 className="text-2xl font-bold text-gray mb-12">
                                    SpaceSuite: elimina las tareas repetitivas y céntrate en lo importante
                                </h2>
                                <div className="flex flex-col rounded-xl bg-white p-12 mb-12">
                                    <img src="/images/star.svg" width="40px" alt="Ventas" className="p-4 w-12 h-12 rounded-full bg-green-50 mb-4"/>
                                    <h3 className="text-xl font-bold text-gray mb-4">
                                        VENTAS
                                    </h3>
                                    <span className="text-gray text-base">
                                        Registra oportunidades de negocio, centraliza la información de tus clientes y organiza tareas para asegurarte de cerrar tus ventas con éxito.
                                    </span>
                                </div>
                                <Link className="flex flex-row text-xl items-center font-bold text-green-900">
                                    Aumenta tu productividad <StaticImage src="../../static/images/ArrowGreenRight.svg" quality="100" alt="" className="ml-8"/>
                                </Link>
                            </div>
                            <div className="flex flex-col rounded-xl bg-white p-12 h-content self-end ml-0 lg:ml-12 mt-12 lg:mt-0">
                                    <img src="/images/check.svg" width="40px" alt="Ventas" className="p-4 w-12 h-12 rounded-full bg-green-50 mb-4"/>
                                    <h3 className="text-xl font-bold text-gray mb-4">
                                        LOCALIZACIÓN
                                    </h3>
                                    <span className="text-gray text-base">
                                        Monitorea lo ubicación de tus colaboradores en tiempo real. Crea y asigna lugares como puntos de visita para tus colaboradores.
                                    </span>
                                </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="px-12 py-12 lg:py-40 bg-white">
                <div className="container mx-auto grid grid-cols-1 lg:grid-cols-2 gap-12 lg:gap-24">
                    <div className="col-span-1 pr-12 lg:pr-24">
                        <div className="border h-full w-full rounded-xl bg-transparent border-green-900 relative">
                            <div className="h-full w-full rounded-xl bg-gray-400 absolute top-12 right-12 overflow-hidden flex place-content-center">
                                <StaticImage src="https://cdn01.prd.pentcloud.com/site/Soluciones/2.jpg" className="object-contain w-full" quality="100" alt="Learning"/>
                            </div>
                        </div>
                    </div>
                    <div className="col-span-1">
                        <h2 className="text-4xl font-bold text-gray mb-12">
                            MarketSpace
                        </h2>
                        <div className="flex flex-col">
                            <div className="flex flex-row mb-12">
                                <img src="/images/check.svg" width="40px" alt="Ventas" className="p-4 w-12 h-12 rounded-full bg-green-50 mr-4"/>
                                <span className="lg:text-gray-400 text-gray text-base">
                                    Obtén tu tienda en línea en minutos con inventario centralizado, gestión de pedidos, precios, estadísticas de venta y más
                                </span>
                            </div>
                            <div className="flex flex-row mb-12">
                                <img src="/images/check.svg" width="40px" alt="Ventas" className="p-4 w-12 h-12 rounded-full bg-green-50 mr-4"/>
                                <span className="lg:text-gray-400 text-gray text-base">
                                    Tendrás una bandeja de entrada donde estarán todos tus pedidos actuales, además tus clientes podrán ver el estado a través de un enlace
                                </span>
                            </div>
                            <div className="flex flex-row mb-12">
                                <img src="/images/check.svg" width="40px" alt="Ventas" className="p-4 w-12 h-12 rounded-full bg-green-50 mr-4"/>
                                <span className="lg:text-gray-400 text-gray text-base">
                                    Personaliza MarketSpace con tus colores y logotipo de tu marca. Agrega cuentas bancarias, tipos de monedas y los costos de envío por zonas
                                </span>
                            </div>
                            <Link className="flex flex-row text-xl items-center font-bold text-green-900" to="https://marketspace.digital">
                                Vende en línea ahora <StaticImage src="../../static/images/ArrowGreenRight.svg" quality="100" alt="" className="ml-8"/>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="px-12 py-12 lg:py-40 bg-white">
                <div className="container mx-auto grid grid-cols-1 lg:grid-cols-2 gap-12 lg:gap-24">
                    <div className="col-span-1">
                        <h2 className="text-4xl font-bold text-gray mb-12">
                            Learning
                        </h2>
                        <div className="flex flex-col">
                            <div className="flex flex-row mb-12">
                                <img src="/images/check.svg" width="40px" alt="Ventas" className="p-4 w-12 h-12 rounded-full bg-green-50 mr-4"/>
                                <span className="lg:text-gray-400 text-gray text-base">
                                    Capacita y evalúa vendedores, técnicos, etc. de una forma remota, rápida y fácil para asegurar la capacidad o el conocimiento.
                                </span>
                            </div>
                            <div className="flex flex-row mb-12">
                                <img src="/images/check.svg" width="40px" alt="Ventas" className="p-4 w-12 h-12 rounded-full bg-green-50 mr-4"/>
                                <span className="lg:text-gray-400 text-gray text-base">
                                    Utiliza presentaciones powerpoint, videos, audios o cualquier otro contenido existente se puede aprovechar para convertirse en un curso. 
                                </span>
                            </div>
                            <div className="flex flex-row mb-12">
                                <img src="/images/check.svg" width="40px" alt="Ventas" className="p-4 w-12 h-12 rounded-full bg-green-50 mr-4"/>
                                <span className="lg:text-gray-400 text-gray text-base">
                                    Certifica que a tus colaboradores que sobre algún tema o una política interna.
                                </span>
                            </div>
                            <Link className="flex flex-row text-xl items-center font-bold text-green-900">
                                Capacita a tus colaboradores <StaticImage src="../../static/images/ArrowGreenRight.svg" quality="100" alt="" className="ml-8"/>
                            </Link>
                        </div>
                    </div>
                    <div className="border h-full w-full rounded-xl bg-transparent border-green-900 relative">
                        <div className="h-full w-full rounded-xl bg-gray-400 absolute bottom-12 left-12 overflow-hidden flex place-content-center">
                            <StaticImage src="https://cdn01.prd.pentcloud.com/site/Soluciones/3.jpg" className="object-contain w-full" quality="100" alt="Learning"/>
                            {/* <img src="https://cdn01.prd.pentcloud.com/site/Soluciones/4.jpg"  alt="Pentcloud" className="rounded-3xl shadow-xl transition ease-in-out duration-300" /> */}
                        </div>
                    </div>
                </div>
                <div className="container mx-auto flex flex-col lg:mt-40 mt-12 items-center justify-center rounded-lg bg-blue-900 shadow-xl p-12">
						<h2 className="text-white font-extrabold text-4xl mb-12">
							¿Te interesa mejorar la productividad de tu empresa?
						</h2>
						<AniLink paintDrip hex="#FFFFFF" to="/#mainForm" activeClassName="font-bold text-blue">
                            <Button classes="px-8 py-4 bg-white font-bold text-green-900 rounded-lg">
                                Contáctanos ahora
                            </Button>
                        </AniLink>
				</div>
            </div>
            
        </>
    )
}